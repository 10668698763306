<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-form">
        <div class="login-title">快速注册</div>
        <el-form class="register-form"
                 :model="RegisterForm"
                 ref="RegisterRef"
                 :rules="RegisterRules">
          <el-form-item prop="Account">
            <el-input class="phone-input"
                      v-model.trim="RegisterForm.Account"
                      placeholder="请输入手机号">
            </el-input>
            <div class="phone-86">+86</div>
          </el-form-item>
          <el-form-item prop="VerifyCode">
            <el-input class="verifycode-input"
                      v-model.trim="RegisterForm.VerifyCode"
                      placeholder="验证码"></el-input>
            <div class="verify-code"
                 @click="getCode">{{getCodeTimeOut < 60 ? '已发送'+getCodeTimeOut+'s':'获取验证码'}}</div>
          </el-form-item>
          <el-form-item prop="PassWord">
            <el-input v-model.trim="RegisterForm.PassWord"
                      type="password"
                      show-password
                      placeholder="请输入密码（必填，6-20数字、字母组合）"></el-input>
          </el-form-item>
          <el-form-item prop="surePassWord">
            <el-input v-model.trim="RegisterForm.surePassWord"
                      type="password"
                      show-password
                      placeholder="确认密码（必填）"></el-input>
          </el-form-item>
          <el-form-item prop="InviteCode">
            <el-input v-model.trim="RegisterForm.InviteCode"
                      :disabled="isHasInviteCode? true:false "
                      placeholder="请输入邀请码（选填）"></el-input>
          </el-form-item>
        </el-form>
        <div class="login-Agreement">
          <el-checkbox v-model="Agreement">我已阅读并同意</el-checkbox>
          <span>《用户协议》</span>
          <span style="float: right; margin-top:2px;"
                @click="$router.push('/login')">已有账号，立即登录</span>
        </div>
        <div class="login-btn">
          <el-button type="base"
                     @click="register">立即注册</el-button>
          <div @click="LiJiTiyan">已注册，直接下载客户端</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var Timer
export default {
  data () {
    var IsPhone = (rule, value, callback) => {
      const RegExp = /^(0|86|17951)?(1[3-9][0-9])[0-9]{8}$/
      if (!RegExp.test(value)) {
        callback(new Error('请输入正确的手机号！'));
        return;
      }
      callback();
    }
    var validateSuerPwd = (rule, value, callback) => {
      if (this.RegisterForm.PassWord !== value) {
        callback(new Error('输入的密码不一致'));
        return;
      }
      callback();
    }
    return {
      Agreement: false,  // 用户协议
      getCodeTimeOut: 60,
      isHasInviteCode: window.sessionStorage.getItem('InCode') ? true : false,
      RegisterForm: {
        Account: '',
        PassWord: '',
        surePassWord: '',
        VerifyCode: '',
        InviteCode: window.sessionStorage.getItem('InCode') ? window.sessionStorage.getItem('InCode') : ''
      },
      RegisterRules: {
        Account: [{ required: true, message: '请输入账号', trigger: 'blur' }, { validator: IsPhone }],
        PassWord: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        surePassWord: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: validateSuerPwd }
        ],
        VerifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
    }
  },
  methods: {
    // 注册
    register () {
      this.$refs.RegisterRef.validate(async bool => {
        if (!bool) return
        if (!this.Agreement) return this.$message.warning('请勾选同意用户协议')
        const { data: res } = await this.$axios.post('/api/Merchant/RegisterMerchant', this.RegisterForm)
        if (res.nResult != 1) return
        this.$message.success('注册成功~')
        this.$refs.RegisterRef.resetFields()
        this.RegisterRef = {
          Account: '',
          PassWord: '',
          surePassWord: '',
          VerifyCode: '',
          InviteCode: ''
        }
        this.LiJiTiyan()
      })
    },
    // 获取验证码
    async getCode () {
      const RegExp = /^(0|86|17951)?(1[3-9][0-9])[0-9]{8}$/
      if (!RegExp.test(this.RegisterForm.Account)) return this.$message.warning('请输入正确的手机号~')
      if (this.getCodeTimeOut != 60) return
      const formData = {
        Account: this.RegisterForm.Account,
        PhoneNumber: this.RegisterForm.Account,
      }
      const { data: res } = await this.$axios.post('/api/System/SendVerificationCode', formData)
      if (res.nResult = 1) {
        Timer = setInterval(() => {
          this.getCodeTimeOut--
          if (this.getCodeTimeOut == 0) {
            clearInterval(Timer)
            this.getCodeTimeOut = 60
          }
        }, 1000);
      }


    },

    LiJiTiyan () {
      // 删除邀请码
      window.sessionStorage.removeItem('InCode')
      this.$router.push('/download')
      this.setSessionItem('routerName', '/download')
    },
  },
}
</script>

<style lang="less" scoped>
.login-page {
  background: linear-gradient(132deg, #e9fff9 0%, #caefff 100%);
  padding: 120px 0 60px 0;
}
.login-box {
  width: 1000px;
  height: 520px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin: 0 auto;
  overflow: hidden;
  .login-form {
    text-align: left;
    width: 400px;
    margin: 0 auto;
    .login-title {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      position: relative;
      margin: 20px 0 30px 0;
    }
    .login-title::after {
      content: '';
      width: 80px;
      height: 4px;
      background: #3cb371;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
    .el-input {
      width: 400px;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .login-Agreement {
      overflow: hidden;
      span {
        font-size: 14px;
        color: #3b7cff;
        cursor: pointer;
      }
    }
  }
}

.login-btn {
  .el-button {
    width: 400px;
    margin: 40px 0 10px 0;
  }
  div {
    width: 400px;
    font-size: 14px;
    cursor: pointer;
    color: #3cb371;
    text-align: center;
  }
}

.register-form {
  .el-form-item {
    position: relative;
  }
}
/deep/.phone-input {
  .el-input__inner {
    padding: 0 0 0 90px;
  }
}

.phone-86 {
  width: 50px;
  height: 20px;
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  left: 20px;
  line-height: 22px;
  border-right: 1px solid #dcdfe6;
}

.verify-code {
  width: 130px;
  height: 20px;
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  right: 0;
  text-align: center;
  line-height: 22px;
  color: #3cb371;
  border-left: 1px solid #dcdfe6;
  cursor: pointer;
}
</style>
